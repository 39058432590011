import { React, Component } from 'preact';
import { lazy, Suspense } from 'preact/compat';

import '../utils/public-path';
const StandaloneSearchBox = lazy(() => import('./StandaloneSearchBox'));

class App extends Component {
	constructor(props) {
		super(props);

		this.state = {
			loaded: false,
			focusSearchBox: false
		}
	}
	
	componentDidMount() {
		let searchHeaderIcon = document.querySelector('.header__searchToggle');
		if (searchHeaderIcon) {
			searchHeaderIcon.addEventListener('click', () => {
				this.setState({
					loaded: true,
					focusSearchBox: true
				});
			});
		}

		setTimeout(() => {
			this.setState({
				loaded: true
			});
		}, 5000);
	}

	handleSearchBoxFocused = () => {
		this.setState({
			focusSearchBox: false
		});
	}

	render() {
		if (this.state.loaded) {
			return (
				<Suspense>
					<StandaloneSearchBox onSearchBoxFocused={this.handleSearchBoxFocused} focusSearchBox={this.state.focusSearchBox} redirectionUrl={this.props.searchPage} />
				</Suspense>
			);
		}
		return null;
	}
}

export default App;